import React from 'react';
import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import useQueryParams from './useQueryParams';
import { redirectToUrl, redirectToUrlWithUTM } from './redirectToUrl'; // Import both functions
import { firebaseConfig } from '../firebase';

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const RedirectComponent = () => {
  const [errorType, setErrorType] = React.useState(null);
  const { slug, token, utmSource, utmMedium, utmCampaign, utmContent } = useQueryParams();

  React.useEffect(() => {
    // Decide if redirectToUrlWithUTM is needed
    if (utmSource || utmMedium || utmCampaign || utmContent) {
      // If there are UTM parameters, use redirectToUrlWithUTM
      redirectToUrlWithUTM(slug, token, setErrorType, analytics, { utmSource, utmMedium, utmCampaign, utmContent });
    } else {
      // Otherwise, use redirectToUrl
      redirectToUrl(slug, token, setErrorType, analytics);
    }
  }, [slug, token, utmSource, utmMedium, utmCampaign, utmContent]);

  return (
    <div>
      {errorType === '404' ? (
        <div id="error">
          <p>Error: La URL no es válida o no se encontró</p>
        </div>
      ) : errorType === '500' ? (
        <div>
          <h2>Para acceder a este link necesitas tener descargada la APP</h2>
          <h2>¿No tienes la app descargada aún? ¡Descargarla aquí!</h2>
          <div className="w-100">
            <a className="container-badge" href="https://play.google.com/store/apps/details?id=com.dixtra.zenks.nippydelivery&hl=es_AR&gl=US&pli=1">
              <img className="img-badge" src="https://lh3.googleusercontent.com/q1k2l5CwMV31JdDXcpN4Ey7O43PxnjAuZBTmcHEwQxVuv_2wCE2gAAQMWxwNUC2FYEOnYgFPOpw6kmHJWuEGeIBLTj9CuxcOEeU8UXyzWJq4NJM3lg=s0" alt="Badge Play Store" />
            </a>
            <a className="container-badge" href="https://apps.apple.com/gb/app/nippy/id1670420818">
              <img className="img-badge" src="https://cdn.shopify.com/s/files/1/0408/0871/0293/files/Apple-Store-badge.png?v=1628069004" alt="Badges App Store" />
            </a>
          </div>
          <h2>Una vez descargada la app, vuelve a escanear este QR</h2>
        </div>
      ) : (
        <div id="loader">
          <p>Cargando...</p>
        </div>
      )}
    </div>
  );
};

export default RedirectComponent;
