import './App.css';
import RedirectComponent from './components/redirectComponent';

function App() {
  return (
    <div className="App">
      <RedirectComponent></RedirectComponent>
    </div>
  );
}

export default App;
