// useQueryParams.js
const useQueryParams = () => {
  const urlParams = new URLSearchParams(window.location.search);
  let token = urlParams.get('token');
  const slug = window.location.pathname.split('/')[1];

  // Agrega la obtención de parámetros UTM
  const utmSource = urlParams.get('utm_source');
  const utmMedium = urlParams.get('utm_medium');
  const utmCampaign = urlParams.get('utm_campaign');
  const utmContent = urlParams.get('utm_content');

  if (token) {
    localStorage.setItem('token', token);
  } else {
    token = localStorage.getItem('token');
  }

  return { slug, token, utmSource, utmMedium, utmCampaign, utmContent };
};

export default useQueryParams;
