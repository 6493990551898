import { logEvent } from "firebase/analytics";
import logo from '../logo_solo.png';

const showModal = (message, duration, imageUrl) => {
  const modalOverlay = document.createElement('div');
  modalOverlay.style.position = 'fixed';
  modalOverlay.style.top = '0';
  modalOverlay.style.left = '0';
  modalOverlay.style.right = '0';
  modalOverlay.style.bottom = '0';
  modalOverlay.style.backgroundColor = 'rgba(0, 0, 0, 0.5)';
  modalOverlay.style.display = 'flex';
  modalOverlay.style.justifyContent = 'center';
  modalOverlay.style.alignItems = 'center';
  modalOverlay.style.zIndex = '1000';

  const modal = document.createElement('div');
  modal.style.backgroundColor = 'white';
  modal.style.padding = '20px';
  modal.style.borderRadius = '5px';
  modal.style.display = 'flex';
  modal.style.alignItems = 'center';
  modal.style.textAlign = 'left';

  const image = document.createElement('img');
  image.src = imageUrl;
  image.alt = 'Loading...';
  image.style.width = '100px';
  image.style.height = '100px';
  image.style.marginRight = '20px';

  const messageParagraph = document.createElement('p');
  messageParagraph.textContent = message;

  modal.appendChild(image);
  modal.appendChild(messageParagraph);
  modalOverlay.appendChild(modal);
  document.body.appendChild(modalOverlay);

  setTimeout(() => {
    document.body.removeChild(modalOverlay);
  }, duration);
};

const redirectToUrl = async (slug, token, setShowError, analytics) => {
  const url = process.env.REACT_APP_BASE_URL;
  const profileUrl = process.env.REACT_APP_PROFILE_APP_BASE_URL;

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token ? `Bearer ${token}` : '',
      },
      body: JSON.stringify({ slug }),
    });

    if (response.status === 404) {
      throw new Error('404');
    } else if (response.status === 500) {
      throw new Error('500');
    } else if (response.ok) {
      const data = await response.json();
      let url = "";
      if (data?.isSSOMandatory) {
        const profileResponse = await fetch(`${profileUrl}/redirects`, {
          method: 'GET',
          headers: {
            'Authorization': token ? `Bearer ${token}` : '',
          }
        });
        const profileData = await profileResponse.json();
        url = getNippySSOUrl(removeTokenFromUrl(window.location.href), profileData);

        showModal(profileData.redirectMessage || "Nos falta informacion de tu perfil para continuar, en 2 segundos serás redireccionado...", 6000, logo);

        setTimeout(() => {
          window.location.href = url;
        }, 6000);
      } else {
        const pattern = /\?$/;
        url = pattern.test(data.url)
          ? `${data.url}&`
          : `${data.url}?`;

        const utmParameters = `token=${token}&utm_source=${data.utmSource || ''}&utm_medium=${data.utmMedium || ''}&utm_campaign=${data.utmCampaign || ''}&utm_content=${data.utmContent || ''}`;
        url = `${url}${utmParameters}`;

        logEvent(analytics, 'redirect_event', {
          slug,
          redirectTo: url,
        });
        window.location.href = url;
      }
    } else {
      throw new Error('Error al obtener la URL de redireccionamiento');
    }
  } catch (error) {
    console.error('Error al realizar la solicitud:', error);
    setShowError(error.message);
  }
};

const redirectToUrlWithUTM = async (slug, token, setShowError, analytics, utmParams) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_GET_UTM}/${slug}`, {
      method: 'GET',
      headers: {
        'Authorization': token ? `Bearer ${token}` : '',
      }
    });

    if (response.status === 404) {
      throw new Error('404');
    } else if (response.status === 500) {
      throw new Error('500');
    }

    const data = await response.json();
    console.log('Respuesta de la API:', data);

    const defaultUTMParams = {
      token,
      utm_source: data.source,
      utm_medium: data.medium,
      utm_campaign: data.campaign,
      utm_content: data.content,
    };

    const fullUrl = new URL(data?.url);

    Object.keys(defaultUTMParams).forEach(key => {
      const valueFromOriginalURL = new URLSearchParams(window.location.search).get(key);
      const value = valueFromOriginalURL || utmParams[key] || defaultUTMParams[key];
      console.log(`Valor de ${key}:`, value);
      if (value) {
        fullUrl.searchParams.set(key, value);
      }
    });

    const finalUrl = fullUrl.toString();
    logEvent(analytics, 'redirect_event', {
      slug,
      redirectTo: finalUrl,
      ...utmParams
    });

    window.location.href = finalUrl;

  } catch (error) {
    console.error('Error al realizar la redirección:', error);
    setShowError(error.message);
  }
};

export { redirectToUrl, redirectToUrlWithUTM };

const getNippySSOUrl = (urlParams, profileData) => `${process.env.REACT_APP_SSO_FE}?url=${urlParams}&moduleId=${profileData._id}`;

function removeTokenFromUrl(url) {
  const urlObj = new URL(url);
  urlObj.searchParams.delete('token');
  return urlObj.toString();
}
